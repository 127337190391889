/**
 * @fileoverview
 * This file contains utility functions for working with URLs and locales.
 *
 * It is used both by react apps and our web servers. It should not contain any
 * react-specific code (ex. react-router-dom utilities). React code should go in react-utils.ts.
 */
import { DEFAULT_LOCALE_BY_LANGUAGE } from "@faire/web--source/common/consts/DEFAULT_LOCALE";
import { DEFAULT_LOCALES } from "@faire/web--source/common/consts/DEFAULT_LOCALES";
import { getTwoLetterLanguageCode } from "@faire/web--source/common/consts/THREE_TO_TWO_LETTER_LANGUAGE_CODE";
import { TWO_TO_THREE_LETTER_LANGUAGE_CODE } from "@faire/web--source/common/consts/TWO_TO_THREE_LETTER_LANGUAGE_CODE";
import { getLocationOrThrow } from "@faire/web--source/common/globals/getLocation";
import { buildLocale } from "@faire/web--source/common/localization/utils";
import { isRobot } from "@faire/web--source/common/user-agent/isRobot";
import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import { ILocaleKey } from "@faire/web-api--source/indigofair/locale/ILocaleKey";
import isEqual from "lodash/isEqual";
import type { IRoutingContext } from "./RoutingContext";

export const AVAILABLE_TWO_LETTER_LANGUAGE_CODES = new Set(
  Object.keys(TWO_TO_THREE_LETTER_LANGUAGE_CODE).map((it) => it.toLowerCase())
);

export const localizePathForRobot = (path: string, localeKeyString: string) => {
  const slashLocaleString =
    localeKeyString.toUpperCase() === getTwoLetterLanguageCode(Language.ENG)
      ? ""
      : `/${localeKeyString}`;
  if (hasLocale(path)) {
    return `${slashLocaleString}${removeLocaleFromUrl(path)}`;
  } else {
    return `${slashLocaleString}${path}`;
  }
};

export const localizePath = (
  path: string,
  language: keyof typeof Language,
  localeKey?: ILocaleKey
) => {
  let locale: string | undefined;

  // If localeKey is the default locale for its language, just include localeKey.language in the URL.
  // E.g. we should use /fr instead of /fr-fr.
  // Otherwise, include both the language and country from localeKey in the URL. E.g. /en-gb or /fr-ca.
  if (
    localeKey?.language &&
    isEqual(
      localeKey,
      DEFAULT_LOCALE_BY_LANGUAGE[
        localeKey.language as keyof typeof DEFAULT_LOCALE_BY_LANGUAGE
      ]
    )
  ) {
    locale = getTwoLetterLanguageCode(localeKey.language)?.toLowerCase();
  } else if (localeKey) {
    locale = buildLocale(localeKey).toLowerCase();
  } else {
    locale = getTwoLetterLanguageCode(language)?.toLowerCase();
  }

  if (!locale) {
    return path;
  }

  const cleanedPath = hasLocale(path) ? removeLocaleFromUrl(path) : path;
  if (locale === 'en') {
    return `${cleanedPath}`;
  } else {
    return `/${locale}${cleanedPath}`;
  }
};

export const getLocalePrefix = (
  locale?: string,
  localeCountryUrlPrefix?: string,
  allowedRoboLocales?: (string | undefined)[]
): string => {
  if (locale) {
    const localePrefix = locale.split(/[-_]/)[0];

    if (isRobot()) {
      const robotLocalePrefix = getRobotLocalePrefix(getLocationOrThrow().pathname, allowedRoboLocales)
      if (robotLocalePrefix) { return robotLocalePrefix; }
    }

    if (localeCountryUrlPrefix) {
      return `/${localeCountryUrlPrefix}`;
    }

    if (localePrefix === getTwoLetterLanguageCode(Language.ENG).toLowerCase()) {
      return "";
    }

    if (localePrefix && Object.values(DEFAULT_LOCALES).includes(locale)) {
      return `/${localePrefix}`;
    }

    return `/${locale.toLowerCase()}`;
  }

  return "";
};

export const getRobotUrlWithLocale = (
  availableLanguageSelectorLocales: (string | undefined)[],
  canonicalUrl: string | undefined
): string => {
  let urlWithLocale = "https://www.faire.com"
  if (canonicalUrl) {
    const canonicalPathname = canonicalUrl.replace('https://www.faire.com', '')
    const robotLocale = getRobotLocalePrefix(canonicalPathname, availableLanguageSelectorLocales)
    if (robotLocale) {
      urlWithLocale = urlWithLocale + robotLocale
    }
  }
  return urlWithLocale + "/"
}

const getRobotLocalePrefix = (
  pathname: string,
  allowedRoboLocales?: (string | undefined)[]
): string | undefined => {
  const splitPathName = pathname.split("/");
  if (
    splitPathName.length >= 2 &&
    allowedRoboLocales &&
    allowedRoboLocales.includes(splitPathName[1])
  ) {
    return `/${splitPathName[1]}`;
  } else {
    return undefined;
  }
};

export const getPathnameWithoutLocale = (href: string) => {
  let urlWithoutLocale = href;
  if (hasLocale(href)) {
    urlWithoutLocale = removeLocaleFromUrl(href);
  }

  return urlWithoutLocale.split(/[?#]/)[0];
};

export const prependLocaleToUrl = (
  href: string,
  routingContext: IRoutingContext
): string => {
  if (!href.startsWith("/")) {
    return href;
  }

  if (hasLocale(href)) {
    return getLocale(href) === routingContext.canonicalLocale
      ? removeLocaleFromUrl(href)
      : href;
  }

  return `${getLocalePrefix(
    routingContext.locale,
    routingContext.localeCountryUrlPrefix,
    routingContext.allowedRobotLocales
  )}${href}`;
};

export const removeLocaleFromUrl = (href: string): string => {
  if (!href.startsWith("/") || !hasLocale(href)) {
    return href;
  }

  return href
    .split("/")
    .filter((segment) => segment !== getLocale(href))
    .join("/");
};

export const matchLocale = (href?: string, locale?: string): boolean => {
  if (!href?.startsWith("/") || !hasLocale(href)) {
    return true;
  }

  if (hasLocale(href) && locale) {
    const hrefLocale = href.split("/")[1];

    if (hrefLocale && hrefLocale.length > 2 && locale.length === 2) {
      const hrefLocalePrefix = hrefLocale?.split(/[-_]/)[0];
      return locale === hrefLocalePrefix;
    }
  }

  return `/${getLocale(href)}` === getLocalePrefix(locale);
};

export const hasLocale = (path: string): boolean => {
  // verify if the url already starts with a locale
  // the locale has to be of the format /{locale}/ (i.e. /fr/, also /fr-ch/, /de-ch/)
  // so the path has to have at least 3 segments
  // and second segment should be an available locale prefix
  const pathSegments = path.split("/");
  if (pathSegments.length < 2 || !pathSegments[1]) {
    return false;
  }

  const localeString = pathSegments[1];
  let isValidLocaleFormat = true;
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore https://github.com/microsoft/TypeScript/issues/29129
    Intl.getCanonicalLocales(localeString);
  } catch (e) {
    isValidLocaleFormat = false;
  }

  const splitLocaleString = localeString.split("-");
  const isLanguageSupported =
    splitLocaleString[0] !== undefined &&
    AVAILABLE_TWO_LETTER_LANGUAGE_CODES.has(splitLocaleString[0]);
  return isValidLocaleFormat && isLanguageSupported;
};

export const getLocale = (path: string) => {
  if (hasLocale(path)) {
    return path.split("/")[1];
  }
  return undefined;
};
