import { getGlobalLookup } from "../globals/lookup";

interface IFaire {
  singletons?: IFaireSingletons;
}
interface IFaireSingletons {
  getter?: SingletonGetter;
}

export const defaultGetter: SingletonGetter = <T>(InstanceConstructor: {
  new (): T;
}) => {
  let instance: T;
  const getter = (): T => {
    return instance ?? (instance = new InstanceConstructor());
  };
  return getter;
};

const getFaireNamespace = (): IFaire | undefined => {
  // We call getGlobalLookup and skip local storage to avoid a circular dependency on
  // singletonGetter -> getGlobalProperty -> Storage -> singletonGetter
  return getGlobalLookup()("faire") as IFaire | undefined;
};

export type SingletonGetter = <T>(
  InstanceConstructor: { new (): T },
  params?: {
    /**
     * true when the Singleton should not be reset across zones.
     * Some singletons don't use user data, so can safely have their cached
     * values persistent, e.g. LocalizationStore.
     */
    globalSingleton?: boolean;
  }
) => () => T;

export const singletonGetter = <T>(
  InstanceConstructor: { new (): T },
  params?: {
    /**
     * true when the Singleton should not be reset across zones.
     * Some singletons don't use user data, so can safely have their cached
     * values persistent, e.g. LocalizationCacheStore.
     *
     * Also be careful to create global singleton inside worker thread, it
     * caused OOM issue before. Do a local memory benchmark if you really want
     * to create one.
     */
    globalSingleton?: boolean;
  }
): (() => T) => {
  let instance: T;

  if (params?.globalSingleton) {
    return () => instance ?? (instance = new InstanceConstructor());
  }

  const faire = getFaireNamespace();
  const getter = faire?.singletons?.getter ?? defaultGetter;
  return getter(InstanceConstructor, params);
};

/**
 * Sets the global singleton getter, i.e. global.faire["singletons"]["getter"].
 * The request handler is later resolved via getGlobalProperty("faire.singletons.getter"),
 * so in a Zone context (i.e. SSR), this should be done using Zone.fork, setting "faire.singletons.getter".
 */
export const setSingletonGetter = (getter: SingletonGetter) => {
  const faire = getFaireNamespace();
  if (!faire) {
    throw new Error("Faire namespace not found");
  }
  const singletons = faire.singletons || (faire.singletons = {});
  singletons.getter = getter;
};
